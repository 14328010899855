import React, { useEffect, useState } from "react";
import { useMedia } from "use-media";
import Header from "../components/Header";
import Footer from "../components/Footer";
import loadScript from "../react/loadscript";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import Layout from "../components/chat-channel/Layout";
import Container from "../components/chat-channel/Container";
import { OnScrollPopup } from "./conversational-ai-platform/hr-chatbot";
import { GoToPopup } from "./homepage";
import { CookiesPoup } from "./homepage";
import { isBlockedDomain } from "../components/modalform/domain-utils";

const Image = require("../assets/images/contact-us-image.png");

const ldSchema = [
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Workativ Software Solutions",
    description:
      "Save IT Helpdesk costs with Workativ Assistant’s AI-powered Automations. No coding, we promise!",
    url: "https://www.workativ.com",
    logo: "https://workativ.com/workativ-logo.196a2d6c.png",
    founder: {
      "@type": "Person",
      name: "Imran Quraishy",
    },
    sameAs: ["https://www.linkedin.com/company/workativ-com/"],
    address: {
      "@type": "PostalAddress",
      postOfficeBoxNumber: "2810",
      streetAddress: "N Church St,",
      addressRegion: "Wilmington, Delaware,",
      postalCode: "19802-4447",
      addressCountry: "USA",
    },
  },
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Workativ Software Solutions",
    description:
      "Save IT Helpdesk costs with Workativ Assistant’s AI-powered Automations. No coding, we promise!",
    url: "https://www.workativ.com",
    logo: "https://workativ.com/workativ-logo.196a2d6c.png",
    founder: {
      "@type": "Person",
      name: "Imran Quraishy",
    },
    sameAs: ["https://www.linkedin.com/company/workativ-com/"],
    address: {
      "@type": "PostalAddress",
      streetAddress: "Second Floor, M2, Type II, Dr VSI Estate, Thiruvanmiyur ",
      addressLocality: "Chennai",
      addressRegion: "Tamilnadu",
      postalCode: "600041",
      addressCountry: "IN",
    },
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "customer support",
      url: " https://workativ.com/contact-us",
    },
  },
];

const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export default function Home() {
  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
    product: "",
    message: "",
  });

  useEffect(() => {
    loadScript("/contact-us-form.js")
      .then((script) => {
        console.log("load scropt success");
        console.log(script);
        // Grab the script object in case it is ever needed.
        // this.mapScript = script;
        // this.setState({ apiLoaded: true });
      })
      .catch((err) => {
        console.log("load scropt error");
        console.error(err.message);
      });
  }, []);

  function disableSubmit() {
    return (
      state.email.length == 0 ||
      !validateEmail(state.email) ||
      isBlockedDomain(state.email) ||
      (state.mobile.length > 0 && isNaN(state.mobile)) ||
      state.company.length == 0 ||
      state.name.length == 0 ||
      state.product.length == 0 ||
      state.message.length == 0
    );
  }
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Contact Us | Conversational AI and Process Automation platform | Workativ"
        description="Send us a message and join us in new age workplace support automation platform."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={Image}
        ogTitle="Contact Us | Conversational AI and Process Automation platform | Workativ"
        ogDescription="Send us a message and join us in new age workplace support automation platform."
        schema={ldSchema}
      />
      <Container>
        <Layout logoFor="WORKATIV" product="WORKATIV">
          <div>
            <section className="skit_contactus">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 contact-form">
                    <h1>We will be glad to hear from you!</h1>
                    <h5>
                      Send us a message and we'll respond as soon as possible
                    </h5>
                    <div class="sib-form p-0" style="">
                      <div id="sib-form-container" class="sib-form-container">
                        <div
                          id="error-message"
                          class="sib-form-message-panel"
                          style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;max-width:540px;'
                        >
                          <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
                            <svg
                              viewBox="0 0 512 512"
                              class="sib-icon sib-notification__icon"
                            >
                              <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
                            </svg>
                            <span class="sib-form-message-panel__inner-text">
                              Your request could not be saved. Please try again.
                            </span>
                          </div>
                        </div>
                        <div></div>
                        <div
                          id="success-message"
                          class="sib-form-message-panel"
                          style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#085229; background-color:#e7faf0; border-radius:3px; border-color:#13ce66;max-width:540px;'
                        >
                          <div class="sib-form-message-panel__text sib-form-message-panel__text--center">
                            <svg
                              viewBox="0 0 512 512"
                              class="sib-icon sib-notification__icon"
                            >
                              <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
                            </svg>
                            <span class="sib-form-message-panel__inner-text">
                              Thank you for contacting us.
                            </span>
                          </div>
                        </div>
                        <div></div>
                        <div
                          id="sib-container"
                          class="sib-container--large sib-container--vertical p-0"
                          style="text-align:center; direction:ltr"
                        >
                          <form
                            id="sib-form"
                            method="POST"
                            action="https://547c95d4.sibforms.com/serve/MUIFABSCZudeA4osLQbeaxgKA1CUYf481m6DnS-iEhozXPWLBnOZ3slOBUTb-H8H_gc_hGyiMH1vCjMq6_OLCfLEEYBLOcURJaQPXCb6VacSWVTsUjfP4BI5yuuo9mLNH3uXDDO5fJH0WQy-vCVxASC7MSVHcx7z5howmmClTA1VoGg4vQCwqU-ufwh9g37TfTZ0JOplfVMrM8r1"
                            data-type="subscription"
                          >
                            <div
                              className={`${!isSmall ? "d-flex gap-20" : ""}`}
                            >
                              <div style="padding: 8px 0;" className="flex-1">
                                <div class="sib-input sib-form-block pl-0 pr-0">
                                  <div class="form__entry entry_block">
                                    <div class="form__label-row ">
                                      <label
                                        class="entry__label"
                                        style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                        for="NAME"
                                        data-required="*"
                                      >
                                        Full Name
                                      </label>

                                      <div class="entry__field">
                                        <input
                                          class="input "
                                          maxlength="200"
                                          type="text"
                                          id="NAME"
                                          name="NAME"
                                          autocomplete="off"
                                          placeholder="Type your name"
                                          data-required="true"
                                          required
                                          style={`${
                                            isSmall ? "height:fit-content;" : ""
                                          }`}
                                        />
                                      </div>
                                    </div>

                                    <label
                                      class="entry__error entry__error--primary"
                                      style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                    ></label>
                                  </div>
                                </div>
                              </div>
                              <div style="padding: 8px 0;" className="flex-1">
                                <div class="sib-input sib-form-block pl-0 pr-0">
                                  <div class="form__entry entry_block">
                                    <div class="form__label-row ">
                                      <label
                                        class="entry__label"
                                        style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                        for="EMAIL"
                                        data-required="*"
                                      >
                                        Email
                                      </label>

                                      <div class="entry__field">
                                        <input
                                          class="input "
                                          type="text"
                                          id="EMAIL"
                                          name="EMAIL"
                                          autocomplete="off"
                                          placeholder="Type your corporate email"
                                          data-required="true"
                                          required
                                          style={`${
                                            isSmall ? "height:fit-content;" : ""
                                          }`}
                                        />
                                      </div>
                                    </div>

                                    <label
                                      class="entry__error entry__error--primary"
                                      style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`${!isSmall ? "d-flex gap-20" : ""}`}
                            >
                              <div style="padding: 8px 0;" className="flex-1">
                                <div class="sib-input sib-form-block pl-0 pr-0">
                                  <div class="form__entry entry_block">
                                    <div class="form__label-row ">
                                      <label
                                        class="entry__label"
                                        style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                        for="MOBILE_NUMBER"
                                        data-required="*"
                                      >
                                        Mobile number
                                      </label>
                                      <div class="entry__field">
                                        <input
                                          maxlength="200"
                                          type="text"
                                          data-numeric="true"
                                          class="input "
                                          id="MOBILE_NUMBER"
                                          name="MOBILE_NUMBER"
                                          autocomplete="off"
                                          placeholder="Type your mobile number"
                                          data-required="true"
                                          required
                                          style={`${
                                            isSmall ? "height:fit-content;" : ""
                                          }`}
                                        />
                                      </div>
                                    </div>
                                    <label
                                      class="entry__error entry__error--primary"
                                      style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                    ></label>
                                  </div>
                                </div>
                              </div>
                              <div style="padding: 8px 0;" className="flex-1">
                                <div class="sib-input sib-form-block pl-0 pr-0">
                                  <div class="form__entry entry_block">
                                    <div class="form__label-row ">
                                      <label
                                        class="entry__label"
                                        style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                        for="COMPANY_NAME"
                                        data-required="*"
                                      >
                                        Company
                                      </label>

                                      <div class="entry__field">
                                        <input
                                          class="input "
                                          maxlength="200"
                                          type="text"
                                          id="COMPANY_NAME"
                                          name="COMPANY_NAME"
                                          autocomplete="off"
                                          placeholder="Type your company name"
                                          data-required="true"
                                          required
                                          style={`${
                                            isSmall ? "height:fit-content;" : ""
                                          }`}
                                        />
                                      </div>
                                    </div>

                                    <label
                                      class="entry__error entry__error--primary"
                                      style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style="padding: 8px 0;">
                              <div
                                class="sib-radiobutton-group sib-form-block pr-0 pl-0"
                                data-required="true"
                              >
                                <div class="form__entry entry_mcq">
                                  <div class="form__label-row ">
                                    <label
                                      class="entry__label"
                                      style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                      for="CONTACT_PRODUCT"
                                      data-required="*"
                                    >
                                      Select the product
                                    </label>
                                    <div>
                                      <div class="entry__choice" style="">
                                        <label>
                                          <input
                                            type="radio"
                                            name="CONTACT_PRODUCT"
                                            class="input_replaced"
                                            value="1"
                                            required
                                          />
                                          <span
                                            class="radio-button"
                                            style="margin-left: "
                                          ></span>
                                          <span style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#3C4858; background-color:transparent;'>
                                            Workativ Assistant (AI chatbot
                                            platform with app workflows)
                                          </span>{" "}
                                        </label>
                                      </div>
                                      <div class="entry__choice" style="">
                                        <label>
                                          <input
                                            type="radio"
                                            name="CONTACT_PRODUCT"
                                            class="input_replaced"
                                            value="2"
                                            required
                                          />
                                          <span
                                            class="radio-button"
                                            style="margin-left: "
                                          ></span>
                                          <span style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#3C4858; background-color:transparent;'>
                                            Workativ Automate (App workflow
                                            automation platform)
                                          </span>{" "}
                                        </label>
                                      </div>
                                      <div class="entry__choice" style="">
                                        <label>
                                          <input
                                            type="radio"
                                            name="CONTACT_PRODUCT"
                                            class="input_replaced"
                                            value="3"
                                            required
                                          />
                                          <span
                                            class="radio-button"
                                            style="margin-left: "
                                          ></span>
                                          <span style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#3C4858; background-color:transparent;'>
                                            General Query
                                          </span>{" "}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <label
                                    class="entry__error entry__error--primary"
                                    style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                  ></label>
                                </div>
                              </div>
                            </div>
                            <div style="padding: 8px 0;">
                              <div class="sib-input sib-form-block pr-0 pl-0">
                                <div class="form__entry entry_block">
                                  <div class="form__label-row ">
                                    <label
                                      class="entry__label"
                                      style='font-weight: 700; text-align:left; font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#3c4858;'
                                      for="MESSAGE"
                                      data-required="*"
                                    >
                                      Message
                                    </label>

                                    <div class="entry__field">
                                      <textarea
                                        rows="2"
                                        class="input "
                                        maxlength="500"
                                        id="MESSAGE"
                                        name="MESSAGE"
                                        autocomplete="off"
                                        placeholder="Entre message"
                                        data-required="true"
                                        required
                                      ></textarea>
                                    </div>
                                  </div>

                                  <label
                                    class="entry__error entry__error--primary"
                                    style='font-size:16px; text-align:left; font-family:"Helvetica", sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;'
                                  ></label>
                                </div>
                              </div>
                            </div>
                            <div style="padding: 8px 0;">
                              <div
                                class="sib-form-block pr-0 pl-0"
                                style="text-align: left"
                              >
                                <button
                                  class="sib-form-block__button sib-form-block__button-with-loader"
                                  style='font-size:16px; text-align:left; font-weight:700; font-family:"Helvetica", sans-serif; color:#FFFFFF; background-color:#2468f6; border-radius:30px; border:1px solid #2468f6; padding: 8px 55px; width: fit-content;'
                                  form="sib-form"
                                  type="submit"
                                >
                                  <svg
                                    class="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                                  </svg>
                                  Submit
                                </button>
                              </div>
                            </div>

                            <input
                              type="text"
                              name="email_address_check"
                              value=""
                              class="input--hidden"
                            />
                            <input type="hidden" name="locale" value="en" />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 contact-right-img">
                    <img loading="lazy" src={Image} alt="contactus" />
                  </div>
                </div>
              </div>
            </section>

            <section className="our_locations">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12 col-12 location_places">
                    <h2>Our offices</h2>
                    <div className="center_contactus">
                      <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12 our_location_places">
                        <h4>USA</h4>
                        <ul>
                          <li>2810 N Church St,</li>
                          <li>PMB 191203,</li>
                          <li>Wilmington, Delaware, 19802-4447</li>
                          <li>USA</li>
                        </ul>
                        <div className="our_location_number">
                          <p>Tel: +1 (302) 567-4423</p>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12 our_location_places">
                        <h4>India</h4>
                        <ul>
                          <li>Second Floor, M2, Type II,</li>
                          <li>Dr VSI Estate, Thiruvanmiyur,</li>
                          <li>Chennai - 600041, India.</li>
                        </ul>
                        <div className="our_location_number">
                          <p>Tel: +(91) 98841 70123</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Layout>
      </Container>
    </>
  );
}
